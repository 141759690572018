import axios from '@/common/axios'
import qs from 'qs'

export function addPurifiedWaterMonitor (data) {
  return axios({
    method: 'post',
    url: '/device/purifiedWaterMonitor/add',
    data: qs.stringify(data)
  })
}

export function deletePurifiedWaterMonitor (id) {
  return axios({
    method: 'delete',
    url: '/device/purifiedWaterMonitor/delete/' + id
  })
}

export function updatePurifiedWaterMonitor (data) {
  return axios({
    method: 'put',
    url: '/device/purifiedWaterMonitor/update',
    data: qs.stringify(data)
  })
}

export function selectPurifiedWaterMonitorInfo (id) {
  return axios({
    method: 'get',
    url: '/device/purifiedWaterMonitor/info/' + id
  })
}

export function selectPurifiedWaterMonitorList (query) {
  return axios({
    method: 'get',
    url: '/device/purifiedWaterMonitor/list',
    params: query
  })
}
