<template>
  <div id="purifiedWaterMonitor">
    <el-dialog
      :title="purifiedWaterMonitorFormTitle"
      width="1500px"
      :visible.sync="purifiedWaterMonitorDialogVisible"
      :close-on-click-modal="false"
      @close="purifiedWaterMonitorDialogClose"
    >
      <el-form
        ref="purifiedWaterMonitorFormRef"
        :model="purifiedWaterMonitorForm"
        :rules="purifiedWaterMonitorFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="设备编号" prop="deviceId">
              <el-select
                v-model="purifiedWaterMonitorForm.deviceId"
                placeholder="请选择设备"
                clearable
                @change="devChange"
              >
                <el-option
                  v-for="item in devList"
                  :key="item.id"
                  :label="item.deviceNo"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.deviceNo }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input v-model="purifiedWaterMonitorForm.deviceName" placeholder="请输入设备名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年份" prop="year">
              <el-input v-model="purifiedWaterMonitorForm.year" placeholder="请输入年份" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="月份" prop="month">
              <el-input v-model="purifiedWaterMonitorForm.month" placeholder="请输入月份" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="purifiedWaterMonitorFormTitle === '新增纯化水系统监测记录'
              || purifiedWaterMonitorFormTitle === '修改纯化水系统监测记录'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="monitorDate"
              title="日期"
              width="150"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column
              field="firstLevel1"
              title="1级RO电导率(us/cm)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="firstLevel2"
              title="1级浓水压力(mpa)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="firstLevel3"
              title="1级浓水流量(lpm)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="firstLevel4"
              title="1级产水流量(lpm)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="firstLevel5"
              title="1级高压泵出口压力(mpa)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="waterTankLevel"
              title="纯水箱液位"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="secondLevel1"
              title="2级RO电导率(us/cm <1)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="secondLevel2"
              title="2级浓水压力(mpa)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="secondLevel3"
              title="2级浓水流量(lpm)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="secondLevel4"
              title="2级产水流量(lpm)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="secondLevel5"
              title="2级高压泵出口压力(mpa)"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="purifiedWaterMonitorFormTitle === '新增纯化水系统监测记录'
                || purifiedWaterMonitorFormTitle === '修改纯化水系统监测记录'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="purifiedWaterMonitorDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="purifiedWaterMonitorFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="月份">
        <el-input v-model="searchForm.month" placeholder="请输入月份" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="purifiedWaterMonitorPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="year" label="年份" />
      <el-table-column prop="month" label="月份" />
      <el-table-column prop="monitor" label="监测人" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="purifiedWaterMonitorPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addPurifiedWaterMonitor,
  deletePurifiedWaterMonitor,
  updatePurifiedWaterMonitor,
  selectPurifiedWaterMonitorInfo,
  selectPurifiedWaterMonitorList
} from '@/api/device/purifiedWaterMonitor'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      purifiedWaterMonitorDialogVisible: false,
      purifiedWaterMonitorFormTitle: '',
      purifiedWaterMonitorForm: {
        id: '',
        deviceId: '',
        deviceNo: '',
        deviceName: '',
        year: '',
        month: '',
        detailJson: ''
      },
      purifiedWaterMonitorFormRules: {
        month: [{ required: true, message: '月份不能为空', trigger: ['blur', 'change']}]
      },
      purifiedWaterMonitorPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        month: ''
      },
      devList: [],
      detailList: []
    }
  },
  created () {
    selectPurifiedWaterMonitorList(this.searchForm).then(res => {
      this.purifiedWaterMonitorPage = res
    })
    selectBaseInfoList().then(res => {
      this.devList = res.list
    })
  },
  methods: {
    purifiedWaterMonitorDialogClose () {
      this.$refs.purifiedWaterMonitorFormRef.resetFields()
      this.detailList = []
    },
    purifiedWaterMonitorFormSubmit () {
      if (this.purifiedWaterMonitorFormTitle === '纯化水系统监测记录详情') {
        this.purifiedWaterMonitorDialogVisible = false
        return
      }
      this.$refs.purifiedWaterMonitorFormRef.validate(async valid => {
        if (valid) {
          this.purifiedWaterMonitorForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.purifiedWaterMonitorFormTitle === '新增纯化水系统监测记录') {
            this.purifiedWaterMonitorForm.id = ''
            await addPurifiedWaterMonitor(this.purifiedWaterMonitorForm)
          } else if (this.purifiedWaterMonitorFormTitle === '修改纯化水系统监测记录') {
            await updatePurifiedWaterMonitor(this.purifiedWaterMonitorForm)
          }
          this.purifiedWaterMonitorPage = await selectPurifiedWaterMonitorList(this.searchForm)
          this.purifiedWaterMonitorDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.purifiedWaterMonitorFormTitle = '新增纯化水系统监测记录'
      this.purifiedWaterMonitorDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deletePurifiedWaterMonitor(row.id)
        if (this.purifiedWaterMonitorPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.purifiedWaterMonitorPage = await selectPurifiedWaterMonitorList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.purifiedWaterMonitorFormTitle = '修改纯化水系统监测记录'
      this.purifiedWaterMonitorDialogVisible = true
      this.selectPurifiedWaterMonitorInfoById(row.id)
    },
    handleInfo (index, row) {
      this.purifiedWaterMonitorFormTitle = '纯化水系统监测记录详情'
      this.purifiedWaterMonitorDialogVisible = true
      this.selectPurifiedWaterMonitorInfoById(row.id)
    },
    selectPurifiedWaterMonitorInfoById (id) {
      selectPurifiedWaterMonitorInfo(id).then(res => {
        this.purifiedWaterMonitorForm.id = res.id
        this.purifiedWaterMonitorForm.deviceId = res.deviceId
        this.purifiedWaterMonitorForm.deviceNo = res.deviceNo
        this.purifiedWaterMonitorForm.deviceName = res.deviceName
        this.purifiedWaterMonitorForm.year = res.year
        this.purifiedWaterMonitorForm.month = res.month
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectPurifiedWaterMonitorList(this.searchForm).then(res => {
        this.purifiedWaterMonitorPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectPurifiedWaterMonitorList(this.searchForm).then(res => {
        this.purifiedWaterMonitorPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectPurifiedWaterMonitorList(this.searchForm).then(res => {
        this.purifiedWaterMonitorPage = res
      })
    },
    devChange (value) {
      if (value) {
        let dev = this.devList.find(item => item.id === value)
        this.purifiedWaterMonitorForm.deviceNo = dev.deviceNo
        this.purifiedWaterMonitorForm.deviceName = dev.deviceName
      } else {
        this.purifiedWaterMonitorForm.deviceNo = ''
        this.purifiedWaterMonitorForm.deviceName = ''
      }
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
